<template>
  <div class="home" ref="home">
    <div class="company-top">
      <img
        :src="brandUrl"
        alt=""
        style="margin-right: 16px; width: auto; height: 32px"
      />
      {{ hotelName }}
    </div>
    <div
      style="
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
      "
    >
      <div class="top">
        <!-- <svg-icon
          class="logo"
          iconClass="logo"
          className="icons/svg/logo.svg"
        ></svg-icon>
        <div class="title">{{ stringList.housekeeping_checklist }}</div> -->
        <div>
          <img src="../assets/img/home-title.png" width="210" alt="" />
        </div>
        <!-- <div class="company"> -->
        <!-- {{hotelName}} -->
        <!-- </div> -->
      </div>
      <div class="content">
        <van-form @submit="onSubmit">
          <van-field
            class="input"
            label-class="label"
            v-model="loginParams.username"
            :placeholder="stringList.enter_name"
            :error="verify.username == 2"
            :error-message="errorMessage.username"
          >
            <template #label>
              <svg-icon
                class="label-icon"
                iconClass="name"
                className="icons/svg/name.svg"
              ></svg-icon>
            </template>
            <template #right-icon v-if="verify.username == 1">
              <svg-icon
                class="right-complete"
                iconClass="complete"
                className="icons/svg/complete.svg"
              ></svg-icon>
            </template>
          </van-field>
          <van-field
            class="input"
            label-class="label"
            v-model="loginParams.password"
            type="password"
            @input="isPassword = true"
            :placeholder="stringList.enter_password"
            :error="verify.password == 2"
            :error-message="errorMessage.password"
          >
            <template #label>
              <svg-icon
                class="label-icon"
                iconClass="password"
                className="icons/svg/password.svg"
              ></svg-icon>
            </template>
            <template #right-icon v-if="verify.password == 1">
              <svg-icon
                class="right-complete"
                iconClass="complete"
                className="icons/svg/complete.svg"
              ></svg-icon>
            </template>
          </van-field>
          <div class="language-item">
            <svg-icon
              style="margin-right: 16px"
              class="label-icon"
              iconClass="globe"
              className="icons/svg/globe.svg"
            ></svg-icon>
            <a-select
              size="large"
              style="flex: 1"
              class="language"
              :getPopupContainer="() => $refs.home"
              :default-active-first-option="false"
              :filter-option="false"
              :not-found-content="null"
              @change="languageChange"
              :value="language"
              @focus="clearLanguage"
              dropdownClassName="language-drop"
              :notFoundContent="notFoundContent"
            >
              <a-select-option
                v-for="item in languageList"
                :key="item.id"
                :value="item.value"
              >
                {{ item.type }}
              </a-select-option>
            </a-select>
          </div>
          <div class="submit">
            <van-button
              class="button"
              native-type="submit"
              :loading="loading"
              >{{ loginButton }}</van-button
            >
          </div>
        </van-form>
      </div>
      <div class="foot foot-description">
        {{ stringList.powered_hospitality_voice }}
      </div>
    </div>
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction } from "@/api/manage";
import { Notify } from "vant";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      url: {
        login: "/api/common/user/hotel_dept_name/checkListLogin",
        getUserInfo: "/api/common/user/current_user",
        hotelQuery: "/api/sys_admin/hotel/query",
        hotelinfo: "/adminApi/hotelinfo/infobyhotel",
        getPageInfo: "/adminApi/file/page",
        queryRoomNum: "/api/sys_admin/hotel/room/query2/",
        getKeyWord: "/api/app/current_user/keywordsalldept/",
        getReplyList: "/adminApi/reply/listbyhotel",
        getIsInputPhone: "/adminApi/textconfig/list",
        createOrder: "/api/common/hotel/data/qrorder/create",
        getFileList: "/adminApi/file/filelist",
        getLanguageList: "/api/common/hotel/data/dict/by_key/language",
        about: "/adminApi/test/qr/about1",
        getMoreLanguage: "/api/common/hotel/data/dict/by_key/languageQr",
      },
      brandUrl: "",
      loading: false,
      address: "Powered by Hospitality Voice Tech Inc.", // footer 地址
      title: "Housekeeping Checklist", // 标题
      loginParams: {
        username: "",
        password: "",
      },
      RRM: false,
      RM: false,
      RS: false,
      notFoundContent: "",
      // placeholderMessage: {
      //   username: "Enter Name",
      //   password: "",
      // },
      verify: {
        username: 0,
        password: 0,
      },
      errorMessage: {
        username: "",
        password: "",
      },
      isPassword: false,
      // currentErrorMessage: {
      //   username: "Incorrect Name",
      //   password: "111",
      // },
      showPicker: false,
      columns: [
        "English",
        "Chinese(Simplified)",
        "Spanish",
        "German",
        "Arabic",
        "Tagalog filipino",
      ],
      hotelName: "",
    };
  },
  computed: {
    ...mapGetters({
      language: "language",
      languageList: "languageList",
      stringList: "stringList",
    }),
    loginButton() {
      return this.stringList.log_in;
    },
  },
  watch: {
    loginParams: {
      handler(value) {
        // console.log(value);
        this.verifyLogin();
      },
      deep: true,
    },
  },
  created() {
    // eslint-disable-next-line no-undef
    gtag("js", new Date());
    // eslint-disable-next-line no-undef
    gtag("config", "G-5LKWZNJ4KN");
    this.getLanguageList();
    let language = localStorage.getItem("language");
    this.$store.dispatch("editLanguage", language || "en");
    let params = Vue.ls.get("user");
    // console.log(params);
    if (!this.$route.query.roomNumber) {
      Vue.ls.remove("roomNumber");
    }
    if (params) {
      this.loginParams.username = Vue.ls.get("user").hotelDeptName;
      this.loginParams.password = Vue.ls.get("user").password;
    }
    this.getHotelName();
  },
  mounted(){
    
  },
  methods: {
    getHotelName() {
      const params = { hotelNo: this.$route.query.inno };
      getAction("/admins/hotel/gethotelByHotelNo", params).then((res) => {
        // console.log(res)
        this.hotelName = res.data.name;
        getAction(
          "/adminApi/hotelinfo/infobyhotel?hotelId=" + res.data.id
        ).then((resp) => {
          // console.log(res)
          // this.brandUrl =
          //   process.env.VUE_APP_BASE_API.slice(0, -4) +
          //   "/logo/" +
          //   resp.data.brand.toLowerCase().replaceAll(" ", "_") +
          //   ".png";
          this.brandUrl =
            "https://hotelfile353485474178.s3-accelerate.amazonaws.com/logo/" +
            resp.data.brand.toLowerCase().replaceAll(" ", "_") +
            ".png";
            if (resp.data.klenniOption) {
              this.RRM = resp.data.klenniOption.includes("Report Room Charge");
              this.RM = resp.data.klenniOption.includes("Request Maintenance");
              this.RS = resp.data.klenniOption.includes("Replenish Supplies");
            } else {
              // this.goChecklist("RRCM");
            }
            if (!this.RM && !this.RS) {
              // this.goChecklist("RRCM");
            }
            if(this.$route.query.user){
              this.loginParams.username = this.$route.query.user;
              this.loginParams.password = this.$route.query.pwd;
              this.onSubmit();
            }
        });
      });
    },
    languageSearch(value) {
      if (value) {
        this.roomArray = this.roomList.filter((item) => {
          return item.name.indexOf(value) != -1;
        });
        this.notFoundContent = this.stringList.invalid_room;
      } else {
        this.roomArray = [];
        this.notFoundContent = "";
      }
    },
    languageChange(value) {
      this.$store.dispatch("editLanguage", value);
    },
    clearLanguage() {
      if (this.roomNumber == undefined || this.roomNumber == "") {
        this.roomArray = this.roomList;
      } else {
        this.roomNumber = "";
        this.roomArray = [];
        this.notFoundContent = "";
        this.roomArray = this.roomList;
      }
    },
    verifyLogin() {
      let params = {
        hotelDeptName: this.loginParams.username,
        hotelName: this.$route.query.inno,
        password: this.loginParams.password,
      };
      // console.log(1111);
      if (!this.loginParams.username) {
        this.verify.username = 2;
        this.errorMessage.username = this.stringList.please_enter_username;
        if (!this.loginParams.password) {
          // this.verify.password = 2;
          // this.errorMessage.password = this.stringList.please_enter_password;
          this.verify.username = 0;
          this.verify.password = 0;
          this.errorMessage.username = "";
          this.errorMessage.password = "";
          this.isPassword = false;
        }
        // console.log(2);
        return;
      }
      if (!this.loginParams.password) {
        if (this.isPassword) {
          this.verify.password = 2;
          this.errorMessage.password = this.stringList.please_enter_password;
        }
        return;
      }
      // console.log(!this.loginParams.password);
      postAction(this.url.login, params).then((res) => {
        if (res.status == 0) {
          // this.token = res.data.token;
          // Vue.ls.set("HTTOKEN", res.data.token);
          // this.$router.push({ name: "CheckList" });
          this.verify.username = 1;
          this.verify.password = 1;
          this.errorMessage.username = "";
          this.errorMessage.password = "";

          return true;
        } else {
          // console.log(
          //   res.msg,
          //   res.msg == "Incorrect username. Please try again."
          // );
          if (res.msg == "Incorrect username. Please try again.") {
            this.verify.username = 2;
            this.errorMessage.username = this.stringList.incorrect_name;
            if (!this.loginParams.username) {
              this.errorMessage.username =
                this.stringList.please_enter_username;
            }
            this.verify.password = 0;
            this.errorMessage.password = "";
          } else if (res.msg == "Incorrect password. Please try again.") {
            this.verify.username = 1;
            this.errorMessage.username = "";
            this.verify.password = 2;
            if (!this.loginParams.password) {
              this.errorMessage.password =
                this.stringList.please_enter_password;
            }
            this.errorMessage.password = this.stringList.incorrect_password;
          } else {
            this.verify.username = 1;
            this.errorMessage.username = "";
            this.verify.password = 1;
            this.errorMessage.password = "";
          }
        }
      });
    },
    async onSubmit(values) {
      this.isPassword = true;
      await this.verifyLogin();
      // console.log("submit", values, this.loginParams);
      let params = {
        hotelDeptName: this.loginParams.username,
        hotelName: this.$route.query.inno,
        password: this.loginParams.password,
      };
      // console.log(this.$route.query)
      // debugger
      const roomNumber = this.$route.query.roomNumber;
      // Vue.ls.set("roomNumber",this.$route.query.roomNumber)
      postAction(this.url.login, params).then((res) => {
        if (res.status == 0) {
          this.token = res.data.token;
          Vue.ls.set("HTTOKEN", res.data.token);
          this.$store.dispatch("user/setUserInfo");
          Vue.ls.set("user", params);
          console.log(1111111111111,this.RM,this.RS)
          if (!this.RM && !this.RS) {
              // this.goChecklist("RRCM");
              Vue.ls.set("type", "RRCM");
              this.$router.push({
                name: "CheckList",
                params: { roomNumber: this.$route.params.roomNumber },
              });
            }else{
              this.$router.push({
                name: "Home",
                params: { roomNumber: roomNumber },
              });
            }
          this.verify.username = 1;
          this.verify.password = 1;
          this.errorMessage.username = "";
          this.errorMessage.password = "";
        } else {
          // console.log(
          //   res.msg,
          //   res.msg == "Incorrect username. Please try again."
          // );
          if (res.msg == "Incorrect username. Please try again.") {
            this.verify.username = 2;
            this.errorMessage.username = this.stringList.incorrect_name;
            this.verify.password = 0;
            this.errorMessage.password = "";
          } else if (res.msg == "Incorrect password. Please try again.") {
            this.verify.username = 1;
            this.errorMessage.username = "";
            this.verify.password = 2;
            this.errorMessage.password = this.stringList.incorrect_password;
          }
        }
      });
    },
    onConfirm(value) {
      this.language = value;
      this.showPicker = false;
    },
    getLanguageList() {
      getAction(this.url.getLanguageList).then((res) => {
        Vue.ls.set("languageList", res.data);
        this.editLanguageList(res.data);
      });
    },
    ...mapActions([
      "editLanguageList",
      "editColor", //
      "editAmcniticsRequest",
      "editInformationRequest",
      "editShowPhone",
      "editRoomList",
      "login",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.home {
  background: #fff;
  text-align: left;
  // width: 100%;
  padding: 0 3.4375rem;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .top {
    // flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .logo {
      width: 4rem;
      height: 4rem;
    }
    .title {
      width: 17rem;
      height: 4rem;
      opacity: 1;
      justify-content: center;
      font-family: Arial;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      display: flex;
      align-items: center;
      letter-spacing: 0em;
      color: #5a578c;
    }
  }
  .content {
    margin-top: 40px;
    // flex: 1;
    .label-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    .right-icon {
      width: 12px;
      height: 6.5px;
      position: relative;
      right: 44px;
    }
    .right-complete {
      width: 19px;
      height: 12.5px;
      position: relative;
      right: 44px;
    }
    .input {
      display: flex;
      align-items: center;
      padding: 0;
      background: #fff0 !important;
      height: 72px;
      padding-bottom: 20px;
      /deep/ .label {
        width: 28px;
        display: flex;
        align-items: center;
      }
      /deep/ input {
        padding: 0 16px;
        // width: 15rem;
        height: 3rem;
        border-radius: 6px;
        opacity: 1;
        // background: #f2f2f2;
        box-sizing: border-box;
        border: 1px solid #afafaf;
      }
    }
    .language-item {
      height: 3rem;
      display: flex;
      align-items: center;
      margin: 12px 0;

      /deep/.ant-select-selection {
        height: 48px;
        display: flex;
        // align-items: center;
        border-radius: 6px;
        opacity: 1;
        background: #f2f2f2;
        box-sizing: border-box;
        border: 1px solid #afafaf;
      }
    }
    .submit {
      margin: 48px 48px 0;
      text-align: center;
      .button {
        width: 200px;
        height: 48px;
        border-radius: 8px;
        opacity: 1;
        background: #ff6600;
        font-family: Arial;
        font-size: 16px;
        font-weight: 700;
        line-height: 18.72px;
        text-align: center;
        // display: flex;
        // align-items: center;
        letter-spacing: 0em;
        color: #ffffff;
      }
    }
  }
  /deep/.van-field--error {
    input {
      color: #ea7652 !important;
      border: 1px solid #ea7652 !important;
    }
    input::placeholder {
      color: #ea7652 !important;
    }
    .van-field__error-message {
      margin-left: 16px;
      height: 50%;
      opacity: 1;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.72px;
      letter-spacing: 0em;
      position: absolute;
      color: #ea7652;
    }
  }
  .foot {
    // width: calc(100% - 110px);
    // position: absolute;
    position: absolute;
    width: 100%;
    bottom: 20px;
    // height: 57px;
    opacity: 1;
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.72px;
    text-align: center;
    display: flex;
    align-items: center;
    letter-spacing: 0em;
    justify-content: center;
    color: #3d3d3d;
    width: 300px;
    margin: auto;
  }
}
.pop {
  word-break: normal;
  text-align: left;
  max-height: 80vh;

  .pop-title {
    margin: 0 30px;
    height: 60px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    // justify-content: center;
    text-align: left;
    position: relative;
    // border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: Arial;
      font-size: 18px;
      line-height: 20px;
      // color: #1e4380;
      font-weight: 600;
    }

    .icon {
      font-size: 22px;
      color: #bbbbbb;
      position: absolute;
      left: 0px;
    }

    .shopping {
      color: #bbbbbb;
      position: absolute;
      right: 0px;
    }

    .van-badge__wrapper {
      display: flex;
    }

    /deep/.van-badge--fixed {
      top: 5px;
    }

    /deep/.van-badge {
      font-family: Arial;
    }
  }

  .pop-title3 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: Arial;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-title2 {
    margin: 0 20px;
    padding-bottom: 10px;
    height: 70px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    border-bottom: 2px solid #e8e8e8;

    .title {
      font-family: Arial;
      font-size: 20px;
      line-height: 22px;
      color: #1e4380;
      font-weight: 600;
    }
  }

  .pop-container {
    // padding: 0 30px;
    max-height: calc(80vh - 160px);
    overflow-y: auto;
    .pop-item-active {
      background: #fbfafa;
    }
    .pop-item {
      min-height: 50px;
      padding: 10px 30px;
      // border-top: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      // justify-content: center;
      text-align: left;
      .active {
        color: #2b5dad !important;
      }
      img {
        height: 22px;
        margin-right: 25px;
      }
      .text {
        color: #000000;
        font-size: 15px;
        line-height: 17px;
        font-family: Arial;
        width: 80px;
      }
      .text2 {
        flex: 0.9;
        color: #555;
        font-size: 13px;
        line-height: 17px;
        font-family: Arial;
      }
      .text3 {
        font-size: 20px;
        line-height: 17px;
        font-family: Arial;
      }
      .icon {
        font-size: 18px;
        color: #bbbbbb;
      }

      &:first-child {
        border-top: 0px;
      }
    }

    .other-select {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      padding-left: 50px;

      .select-other {
        flex: 1;
        font-size: 15px;
        font-family: Arial;
      }

      /deep/.ant-select-selection {
        border-color: transparent;
        background-color: #f3f3f3;
        border-radius: 6px;
        box-shadow: none !important;
      }
      /deep/.ant-select-selection__placeholder {
        color: #9a9a9a;
      }
      /deep/.ant-select-selection:hover {
        border-color: transparent;
        box-shadow: 0 0 0 1px #9a94c0 !important;
      }
    }

    .van-stepper {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
    }

    /deep/.van-stepper--round .van-stepper__minus {
      background-color: #ffffff;
      color: #4f4f4f;
      border: 2px solid #4f4f4f;
    }

    /deep/.van-stepper--round .van-stepper__plus {
      background-color: #ffffff;
      color: #4f4f4f;
      border: 2px solid #4f4f4f;
    }

    /deep/.van-stepper__minus::before,
    /deep/.van-stepper__plus::before {
      height: 2px !important;
    }

    /deep/.van-stepper__minus::after,
    /deep/.van-stepper__plus::after {
      width: 2px !important;
    }

    /deep/.van-stepper--round .van-stepper__input {
      font-family: Arial;
      font-size: 16px;
      // line-height: 22px;
      height: 24px !important;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-area {
    height: 100px;
    border-top: 2px solid #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      height: 47px;
      width: 167px;
      border-radius: 20px;
      background-color: $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 16px;
        line-height: 18px;
        font-family: Arial;
        color: #ffffff;
      }
    }
  }

  .pop-info {
    padding: 0 40px;
    .text {
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      font-family: Arial;
      margin-bottom: 10px;
    }

    .info-item {
      padding-left: 20px;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      font-family: Arial;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $primary-color;
        left: 0px;
        top: 7px;
      }
    }

    .info-item:last-child {
      margin-bottom: 20px;
    }

    /deep/.van-radio__label {
      font-size: 15px;
      line-height: 17px;
      color: #333333;
      font-family: Arial;
    }

    /deep/.van-radio__icon--checked .van-icon {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
/deep/.van-field__right-icon {
  position: absolute;
  right: -30px;
}
body :global(.van-notify) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: auto;
  padding: 10px 16px !important;
  border-radius: 32px !important;
  // background: #f8f7f8 !important;
}
.company {
  margin-top: 15px;
  width: 269px;
  height: 38px;
  opacity: 1;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  letter-spacing: 0em;
  font-variation-settings: "opsz" auto;
  color: #3d3d3d;
}
.company-top {
  // margin-top: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 33.5px;
  // width: 252px;
  height: 60px;
  opacity: 1;

  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0em;

  font-variation-settings: "opsz" auto;
  color: #3d3d3d;
  background: linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0) 100%);
}

</style>
